import Vue from 'vue';
import Router from 'vue-router'
import {list, redirect, route} from "./tools/router-helpers";
import Layout from "../components/Layout";
import SignIn from "../views/SignIn";
import SignOut from "../views/SignOut";
import HelloWorld from "../views/HelloWorld";
import requireLogin from "./tools/route-require-login";

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
  routes: [
    route('/sign-out', 'sign-out', SignOut),
    route('/sign-in', 'sign-in', SignIn),

    list('/', '', Layout, [
      route('', 'main', HelloWorld, requireLogin),
    ], {name: 'main'}),

    redirect('*', {name: 'main'}, requireLogin)
  ]
});


export default router;
