import axios from 'axios';
import Amplify from 'aws-amplify';
import EventBus from '../plugins/event-bus.js';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

function Auth(tokenStore, config) {

    const requestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    Amplify.configure({
        Auth: {
            region: config.region,
            userPoolId: config.userPoolId,
            userPoolWebClientId: config.clientId,
        }
    });

    const transformRequest = (jsonData = {}) =>
        Object.entries(jsonData)
            .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
            .join('&');

    const generateRandomString = (length) => {
        let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        let i = length;
        for (; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    };

    const exchange = async (grantType, request) => {

        return axios.post(`https://${config.appAuthWebDomain}/oauth2/token`,
            transformRequest({
                ...{
                    'grant_type': grantType,
                    'client_id': config.clientId,
                    'redirect_uri': `${config.appWebDomain}/sign-in`
                },
                ...request
            }), requestConfig)
            .then(response => response.data);
    };

    const refreshSession = async () => {
        try {
            let tokens = await exchange('refresh_token', {refresh_token: tokenStore.getRefreshToken()});
            tokenStore.storeIdToken(tokens.id_token);
            return tokenStore.getIdToken();
        } catch (e) {
            console.log('Unable to refresh session', e);
            signOut();
        }
    };

    const getSession = async () => {
        if (tokenStore.getIdToken() && tokenStore.isIdTokenValid()) {
            return tokenStore.getIdToken();
        } else if (tokenStore.getRefreshToken()) {
            return refreshSession()
        } else {
            throw 'Not logged in'
        }
    };

    const pkceChallengeFromVerifier = (code) => {
        const hashed = sha256(code);
        return Base64.stringify(hashed).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    const startFederatedAuth = async () => {
        let state = generateRandomString(8);
        let pkce = generateRandomString(8);
        window.localStorage.setItem('state', state);
        window.localStorage.setItem('pkce', pkce);
        const hashDigest = pkceChallengeFromVerifier(pkce);
        let redirect_uri = encodeURIComponent(`${config.appWebDomain}/sign-in`);
        window.open(`https://${config.appAuthWebDomain}/oauth2/authorize?identity_provider=Azure&redirect_uri=${redirect_uri}&response_type=code&client_id=${config.clientId}&state=${state}&code_challenge=${hashDigest}&code_challenge_method=S256&scope=phone email profile openid`, '_self');
    };

    const completeFederatedAuth = async (query) => {
        if (window.localStorage.getItem('state') !== query.state) {
            throw `Expecting ${window.localStorage.getItem('state')} state param, ${query.state} received`
        }

        let pkce = window.localStorage.getItem('pkce');
        let tokens = await exchange('authorization_code', {code: query.code, code_verifier: pkce});

        tokenStore.storeIdToken(tokens.id_token);
        tokenStore.storeRefreshToken(tokens.refresh_token);

        EventBus.$emit('signed-in');
        return tokenStore.getIdToken();
    };

    const signOut = () => {
        tokenStore.clearCachedTokens();
        let logoutUri = encodeURIComponent(`${config.appWebDomain}/sign-out`);
        window.open(`https://${config.appAuthWebDomain}/logout?logout_uri=${logoutUri}&client_id=${config.clientId}`, '_self');
    };

    const isLoggedIn = () => {
        return tokenStore.getIdToken() != null;
    };

    const isIdTokenValid = () => {
        return tokenStore.isIdTokenValid()
    }


    return {
        getSession,
        isIdTokenValid,
        startFederatedAuth,
        completeFederatedAuth,
        signOut,
        isLoggedIn,
    }

}

export default Auth;
