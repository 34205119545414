import axios from 'axios'

function Links(configGenerator) {

    const axiosInstance = axios.create({
        baseURL: '/api',
    });

    const listLinks = async (lastEvaluatedKey) => {
        return axiosInstance.get(`/links?lastEvaluatedKey=${lastEvaluatedKey ? lastEvaluatedKey : ''}`, await configGenerator.getConfig())
            .then(response => response.data);
    };

    const listOwnedLinks = async (lastEvaluatedKey) => {
        return axiosInstance.get(`/links?owned=true&lastEvaluatedKey=${lastEvaluatedKey ? lastEvaluatedKey : ''}`, await configGenerator.getConfig())
            .then(response => response.data);
    };

    const createLink = async (data) => {
        return axiosInstance.post(`/links`, data, await configGenerator.getConfig())
            .then(response => response.data);
    };

    const deleteLink = async (short) => {
        return axiosInstance.delete(`/links/${short}`, await configGenerator.getConfig())
            .then(response => response.data);
    };

    return {
        listLinks,
        listOwnedLinks,
        createLink,
        deleteLink
    }
}

export default Links
