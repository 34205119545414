<template>
  <v-app>
    <v-app-bar
        app
        color="white"
        flat
    >
      <v-app-bar-title>
        <a href="/">
          <img src="ocado_tech_logo.png" height="48px" width="176px" class="ma-1"/>
        </a>
      </v-app-bar-title>
      <h1 class="font-weight-light ml-4">Links</h1>
      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn slot="activator" @click="signOut" icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon color="grey">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>

      <v-container fluid fill-height>
        <v-layout align-center justify-center fill-height
                  v-if="!loaded">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-layout>
        <router-view v-if="loaded"/>
      </v-container>
    </v-main>

    <v-bottom-sheet v-model="cookies">
      <v-sheet
          class="text-center"
      >
        <div class="py-4">
          We use cookies to make our site work, to analyse our traffic and display customised ads on this and
          other sites. We also use third party cookies from our analytics and targeting partners.
        </div>
        <v-btn
            id="cookieconsent"
            class="mb-6"
            round
            @click="consentCookies"
        >
          Got it!
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <v-footer
        color="#1e2121"
        padless
    >
      <v-row
          justify="center"
          no-gutters
      >
        <v-btn
            v-for="link in links"
            :key="link.key"
            color="white"
            text
            rounded
            class="my-1"
            :href="link.url"
        >
          {{ link.key }}
        </v-btn>
        <v-col
            class="py-2 text-center white--text"
            cols="12"
        >
          Copyright © Ocado Group {{ new Date().getFullYear() }}. All rights reserved.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

import EventBus from './plugins/event-bus.js';
import auth from './auth/'

export default {
  name: 'App',
  async created() {
    if (!window.localStorage.getItem('cookieConsent')) {
      this.cookies = true;
    }

    this.loggedIn = auth.isLoggedIn();
    EventBus.$on('signed-in', async () => {
      this.loggedIn = true;
    });

    if (this.loggedIn && !auth.isIdTokenValid()) {
      await auth.getSession();
      let returnPath = window.sessionStorage.getItem('returnAfterLogin');
      if (returnPath) {
        window.sessionStorage.removeItem('returnAfterLogin');
        window.location.replace(returnPath);
      }
    }
    this.loaded = true;
  },
  data: () => ({
    loaded: false,
    loggedIn: false,
    cookies: false,
    links: [
      {
        key: "Technology Pioneers",
        url: "https://www.ocadogroup.com/technology/technology-pioneers"
      },
      {
        key: "Handbook",
        url: "https://handbook.ocado.tech"
      },
      {
        key: "Careers",
        url: "https://careers.ocadogroup.com/"
      },
      {
        key: "Ocado Technology Blog",
        url: "https://www.ocadogroup.com/technology/blog"
      }
    ]
  }),
  methods: {
    signOut() {
      auth.signOut();
    },
    consentCookies() {
      this.cookies = false;
      window.localStorage.setItem('cookieConsent', "Approved");
    }
  }
};
</script>
