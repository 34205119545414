<template>
  <v-container>
    <v-layout align-center justify-center fill-height>
      <v-flex xs4 class="mb-16">
        <h1 class="font-weight-light">Signed out</h1>
        <p>
          Press
          <router-link :to="{name: 'sign-in'}">here</router-link>
          to log in again.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {}
</script>

