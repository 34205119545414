function TokenStore() {

    let idToken;

    const decode = (str) => {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str.replace(/-/g, '+').replace(/_/g, '/')).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    };

    const getIdToken = () => {
        if (idToken) {
            return idToken;
        } else {
            let tokenExpiry = window.localStorage.getItem('refreshtokenexpiry');
            if(parseInt(tokenExpiry) < new Date().getTime()) {
                clearCachedTokens();
                return null;
            }
            let tokenFromStore = window.localStorage.getItem('idtoken');
            if (tokenFromStore) {
                const jwtPayload = tokenFromStore.split('.')[1];
                const payload = JSON.parse(decode(jwtPayload));
                idToken = {
                    token: tokenFromStore,
                    payload,
                };
                document.cookie = `idtoken=${tokenFromStore}; expires=${new Date(payload.exp * 1000).toUTCString()}; secure; SameSite=Lax`;
                return idToken;
            } else {
                return null;
            }
        }
    };

    const storeIdToken = (token) => {
        window.localStorage.setItem('idtoken', token);
        idToken = null;
        return getIdToken();
    };

    const getRefreshToken = () => {
        return window.localStorage.getItem('refreshtoken');
    };

    const storeRefreshToken = (token) => {
        window.localStorage.setItem('refreshtoken', token);
        window.localStorage.setItem('refreshtokenexpiry', new Date().getTime() + 1000 * 60 * 60 * 24 * 29);
        return getRefreshToken();
    };

    const isIdTokenValid = () => {
        const now = Math.floor(new Date() / 1000);
        try {
            if (idToken != null) {
                return now < idToken.payload.exp;
            }
            return false;
        } catch (e) {
            return false;
        }
    };

    const clearCachedTokens = () => {
        idToken = null;
        document.cookie = 'idtoken=; Max-Age=-99999999;';
        window.localStorage.removeItem('refreshtokenexpiry');
        window.localStorage.removeItem('refreshtoken');
        window.localStorage.removeItem('idtoken');
    };

    return {
        getIdToken,
        storeIdToken,
        getRefreshToken,
        storeRefreshToken,
        isIdTokenValid,
        clearCachedTokens
    }

}

export default TokenStore;
