import auth from "../../auth";

function requireLogin(to, from, next) {
    if (!auth.isIdTokenValid()) {
        if (to.redirectedFrom) {
            window.sessionStorage.setItem('returnAfterLogin', to.redirectedFrom);
        }
        if(auth.isLoggedIn()) {
            next();
        } else {
            next('sign-in');
        }
    } else {
        next();
    }
}

export default requireLogin;
