<template>
  <v-layout justify-center>
    <v-flex md8>
      <v-slide-y-transition mode="out-in">
        <slot>
          <router-view/>
        </slot>
      </v-slide-y-transition>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    // eslint-disable-next-line
    name: 'Layout',
    data() {
      return {};
    }
  };
</script>

<style scoped>
  .v-toolbar {
    background-color: transparent;
  }
</style>
