<template>
    <v-container>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="pink"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="12" class="pb-0">
                <p class="muted">
                    Here you can shorten links and browse already created links. All links created here ara accessible
                    by Ocado employees only, requiring an active company account.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pt-0">
                <v-alert
                        v-if="!!success"
                        type="success">
                    {{success}}
                </v-alert>

                <v-alert
                        v-if="!!error"
                        type="error">
                    {{error}}
                </v-alert>

                <v-form
                        @submit="create" onSubmit="return false;"
                        ref="createForm"
                        v-model="createFormValid"
                        lazy-validation
                >
                    <v-text-field
                            label="Full URL"
                            type="url"
                            dense
                            :loading="createLoading"
                            :disabled="createLoading"
                            v-model="full"
                            :rules="fullRules"
                    ></v-text-field>
                    <v-text-field
                            label="Short URL"
                            type="text"
                            dense
                            :loading="createLoading"
                            :disabled="createLoading"
                            v-model="short"
                            :rules="shortRules"
                    >
                        <template v-slot:prepend>
                            {{domain}}/
                        </template>
                    </v-text-field>

                    <v-btn
                            :disabled="!createFormValid"
                            :loading="createLoading"
                            color="success"
                            type="submit"
                    >
                        Shorten the URL
                    </v-btn>


                </v-form>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12">

                <v-toolbar flat dense
                >

                    <h2 class="font-weight-light">Existing links</h2>
                    <v-spacer></v-spacer>
                    <v-switch
                            class="mt-6"
                            v-model="owned"
                            @change="changeLists"
                            :label="`Created by me only`"
                    ></v-switch>
                </v-toolbar>

                <v-list
                        two-line
                >

                    <v-list-item
                            v-for="link in links"
                            :key="link.short"
                    >
                        <v-list-item-content>


                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-text="`${domain}/${link.short}`" @click="copy(link)"
                                                       class="pointer"
                                                       slot="activator"
                                                       v-bind="attrs"
                                                       v-on="on"
                                    ></v-list-item-title>
                                </template>
                                <span>Click to copy</span>
                            </v-tooltip>


                            <v-list-item-subtitle v-text="link.full"></v-list-item-subtitle>
                            <v-list-item-subtitle>Created by {{link.email}} on {{new
                                Date(link.createdAt).toLocaleDateString()}}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn icon @click="copy(link)">
                                <v-icon color="grey lighten-1">mdi-clipboard</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>


                    <h2 v-if="links && links.length === 0" class="font-weight-light text-sm-center pt-2">
                        There are no links.
                    </h2>

                    <v-progress-linear v-if="links === null || lastEvaluatedKey" height="3"
                                       :indeterminate="true"></v-progress-linear>

                    <v-divider inset
                               v-intersect="onIntersect"></v-divider>

                    <h3 v-if="!lastEvaluatedKey && links !== null" class="font-weight-light text-sm-center pt-2">
                        That's all.
                    </h3>

                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {links} from '../api/'
    import config from '../config'

    export default {
        name: 'HelloWorld',
        methods: {
            async onIntersect(entries) {
                this.isVisible = entries[0].isIntersecting;
                this.load();
            },
            async changeLists() {
                if (this.links) {
                    this.links = null;
                    this.lastEvaluatedKey = null;
                    this.loadedAll = false;
                    await this.internalLoad();
                }
            },
            async internalLoad() {
                let callback = this.owned ? links.listOwnedLinks : links.listLinks;
                let result = await callback(this.lastEvaluatedKey);
                this.lastEvaluatedKey = result.lastEvaluatedKey;
                if (!this.lastEvaluatedKey) {
                    this.loadedAll = true
                }
                this.links = this.links ? this.links : [];
                this.links = this.links.concat(result.items);
            },
            async load() {
                if (this.isVisible && !this.loadedAll) {
                    await this.internalLoad();
                }
            },
            async copy(link) {
                await navigator.clipboard.writeText(`${this.domain}/${link.short}`);
                this.snackbarMessage = `Copied ${this.domain}/${link.short} to your clipboard`;
                this.snackbar = true;
            },
            async create() {
                if (this.$refs.createForm.validate()) {
                    try {
                        this.createLoading = true;
                        this.error = null;
                        this.success = null;
                        await links.createLink({
                            short: this.short,
                            full: this.full
                        })
                        try {
                          this.success = `The ${this.domain}/${this.short} short url was successfully created and copied to your clipboard`;
                          this.snackbarMessage = `The ${this.domain}/${this.short} short url was successfully created and copied to your clipboard`;
                          this.snackbar = true;
                          await navigator.clipboard.writeText(`${this.domain}/${this.short}`);
                        } catch(e) {
                          this.success = `The ${this.domain}/${this.short} short url was successfully created`;
                          this.snackbarMessage = `The ${this.domain}/${this.short} short url was successfully created`;
                          this.snackbar = true;
                          console.log(`Unable to copy to the clipboard `, e)
                        }
                        this.short = null;
                        this.full = null;
                        this.$refs.createForm.reset();
                        await this.changeLists();
                    } catch (err) {
                      console.log(err);
                      if(err.response && err.response.status === 412) {
                        this.error = `The "${this.short}" short url is already taken`;
                      } else {
                        this.error = err;
                      }
                    } finally {
                        this.createLoading = false;
                    }
                }
            }
        },
        data: () => ({
            domain: `${config.appWebDomain}`,
            owned: true,
            links: null,
            snackbar: false,
            snackbarMessage: '',
            lastEvaluatedKey: '',
            loadedAll: false,
            createFormValid: true,
            createLoading: false,
            isVisible: false,
            full: '',
            short: '',
            error: '',
            success: '',
            fullRules: [
                v => !!v || 'Full URL is required',
                v => /https?:\/\/.+/.test(v) || 'Needs to be a valid urls starting with http(s) protocol'
            ],
            shortRules: [
                v => !!v || 'Full URL is required'
            ],
        }),
    }
</script>

<style scoped>
    .muted {
        color: grey;
    }

    .pointer {
        cursor: pointer;
    }
</style>
