<template>

  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="8">
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" lg="8">
        <h1 class="font-weight-light">Already an Ocado Employee?</h1>
        <p>
          Log in with your corporate credentials to open the requested page.
        </p>
        <v-btn
            rounded
            large
            id="logging"
            :loading="loggingIn"
            @click="ssoSignIn">
          <v-icon
              left
              dark
          >
            mdi-login
          </v-icon>

          <span>Log in to navigate to the requested page</span>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="8">
        <h2 class="font-weight-light">Not working with us yet?</h2>

        <p class="mt-4">
          Sorry, to access this page you need to be an Ocado Employee. You can always apply to join Ocado
          Technology, as we are recruiting!
        </p>
        <v-btn block href="https://careers.ocadogroup.com/" color="success"
               rounded>
          Find your next role at Ocado Technology
        </v-btn>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import auth from '../auth/'

export default {
  data: () => ({
    window: 'login',
    path: window.sessionStorage.getItem('returnAfterLogin'),
    loggingIn: false,
  }),
  async created() {
    if (this.$route.query.code) {
      this.loggingIn = true;
      await auth.completeFederatedAuth(this.$route.query);
      let returnPath = window.sessionStorage.getItem('returnAfterLogin');
      if (returnPath) {
        window.sessionStorage.removeItem('returnAfterLogin');
        window.location.replace(returnPath);
      } else {
        this.$router.push({name: 'main'});
      }
    }
  },
  methods: {
    async ssoSignIn() {
      this.loggingIn = true;
      await auth.startFederatedAuth();
    }
  }
}
</script>
