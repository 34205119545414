export function route(path, name, component, beforeEnter) {
  return {path, name, component, beforeEnter};
}

export function list(path, name, component, children = [], redirect) {
  return {name, component, path, children, redirect};
}

export function redirect(path, to, beforeEnter) {
  return {path, redirect: to, beforeEnter};
}
