function ConfigGenerator(auth) {

    const getConfig = async () => {
        let session = await auth.getSession();
        return {
            headers: {
                'Authorization': 'Bearer ' + session.token
            }
        }
    };
    return {
        getConfig
    }
}

export default ConfigGenerator
